import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},

    // Redirect signed-in user to the '/dashboard'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        // canMatch: [NoAuthGuard],
        // component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
            {path: 'payment-cards', loadChildren: () => import('app/modules/payment-card/payment-card.module').then(m => m.PaymentCardModule)},
            {path: 'payment-requests', loadChildren: () => import('app/modules/payment-requests/payment-requests.module').then(m => m.PaymentRequestsModule)},
            {path: 'payments', loadChildren: () => import('app/modules/payment/payment.module').then(m => m.PaymentModule)},
            {path: 'external-payments', loadChildren: () => import('app/modules/external-payment/external-payment.module').then(m => m.ExternalPaymentModule)},
            {path: 'failed-cards', loadChildren: () => import('app/modules/failed-payment-attempt/failed-payment-attempt.module').then(m => m.FailedPaymentAttemptModule)},
            {path: 'card-limits', loadChildren: () => import('app/modules/card-limit/card-limit.module').then(m => m.CardLimitModule)},
            {path: 'wallet', loadChildren: () => import('app/modules/wallet/wallet.module').then(m => m.WalletModule)},
            {path: 'wallet-operations', loadChildren: () => import('app/modules/wallet-operation/wallet-operation.module').then(m => m.WalletOperationModule)},
            {path: 'payment-routes', loadChildren: () => import('app/modules/payment-route/payment-route.module').then(m => m.PaymentRouteModule)},
            {path: 'users', loadChildren: () => import('app/modules/user/user.module').then(m => m.PaymentRouteModule)},
            {path: 'audits', loadChildren: () => import('app/modules/audit/audit.module').then(m => m.AuditModule)},
            {path: 'dead-messages', loadChildren: () => import('app/modules/dead-message/dead-message.module').then(m => m.DeadMessageModule)},
            {path: 'payment-clients', loadChildren: () => import('app/modules/payment-client/payment-client.module').then(m => m.PaymentClientModule)},
            {path: 'access-groups', loadChildren: () => import('app/modules/access-groups/access-groups.module').then(m => m.AccessGroupsModule)},
            {path: 'merchants', loadChildren: () => import('app/modules/merchant/merchant.module').then(m => m.MerchantModule)},
            {path: 'settings', loadChildren: () => import('app/modules/setting/setting.module').then(m => m.SettingModule)},
            {path: 'alerts', children: [
                    {path: 'receivers', loadChildren: () => import('app/modules/alert-receiver/alert-receiver.module').then(m => m.AlertReceiverModule)},
                    {path: 'records', loadChildren: () => import('app/modules/alert-record/alert-record.module').then(m => m.AlertRecordModule)},
                    {path: 'query-definitions', loadChildren: () => import('app/modules/alerts-by-query-definition/alerts-by-query-definition.module').then(m => m.AlertsByQueryDefinitionModule)},
                    {path: 'groups', loadChildren: () => import('app/modules/alert-group/alert-group.module').then(m => m.AlertGroupModule)}
            ]},
            {path: 'reports', loadChildren: () => import('app/modules/report/report.module').then(m => m.ReportModule)},
            {path: 'gift-cards', loadChildren: () => import('app/modules/gift-card/gift-card.module').then(m => m.GiftCardModule)},
            {path: 'pending-payments', loadChildren: () => import('app/modules/pending-payments/pending-payments.module').then(m => m.PendingPaymentsModule)},
            {path: 'black-list', loadChildren: () => import('app/modules/black-list/black-list.module').then(m => m.BlackListModule)},
            {path: 'risk-records', loadChildren: () => import('app/modules/risk-records/risk-records.module').then(m => m.RiskRecordsModule)},
            {path: 'external-payment-balance', loadChildren: () => import('app/modules/external-payment-balance/external-payment-balance.module').then(m => m.ExternalPaymentBalanceModule)},
            {path: 'remittances', children: [
                {path: 'list', loadChildren: () => import('app/modules/remittance/remittance.module').then(m => m.RemittanceModule)},
                {path: 'currencies', loadChildren: () => import('app/modules/currency/currency.module').then(m => m.CurrencyModule)},
                {path: 'countries', loadChildren: () => import('app/modules/country/country.module').then(m => m.CountryModule)},
                {path: 'delivery-types', loadChildren: () => import('app/modules/delivery-type/delivery-type.module').then(m => m.DeliveryTypeModule)},
                {path: 'payment-types', loadChildren: () => import('app/modules/payment-method-type/payment-method-type.module').then(m => m.PaymentMethodTypeModule)},
                {path: 'verifications', loadChildren: () => import('app/modules/verification/verification.module').then(m => m.VerificationModule)},
                {path: 'pending-documents', loadChildren: () => import('app/modules/document/document.module').then(m => m.DocumentModule)},
                // {path: 'user-verifications', loadChildren: () => import('app/modules/user-verification/user-verification.module').then(m => m.UserVerificationModule)},
                // {path: 'pending-verifications', loadChildren: () => import('app/modules/pending-verification/pending-verification.module').then(m => m.PendingVerificationModule)}
            ]},
            { path: 'la-nave', loadChildren: () => import('app/modules/la-nave/la-nave.module').then(m => m.LaNaveModule) },
            { path: 'cubacel', loadChildren: () => import('app/modules/cubacel-recharges/cubacel-recharges.module').then(m => m.CubacelRechargesModule) },
            { path: 'payment-routes-strategies', loadChildren: () => import('app/modules/payment-route-strategy/payment-route-strategy.module').then(m => m.PaymentRouteStrategyModule) },
            { path: 'frontend-feature', loadChildren: () => import('app/modules/frontend-feature/frontend-feature.module').then(m => m.FrontendFeatureModule) },
            { path: 'notification-template', loadChildren: () => import('app/modules/notification-template/notification-template.module').then(m => m.NotificationTemplateModule) },
            { path: 'incidents', loadChildren: () => import('app/modules/incidents/incidents.module').then(m => m.IncidentsModule) },
            { path: 'marketplace', loadChildren: () => import('app/modules/marketplace/marketplace.module').then(m => m.MarketplaceModule) },
            { path: 'promo-codes', loadChildren: () => import('app/modules/promocodes/promocode.module').then(m => m.PromocodeModule)},
        ]
    },
];

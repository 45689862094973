import { Component, ViewChild } from '@angular/core';
// import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
// import DevExpress from "devextreme";
// import DataSource = DevExpress.data.DataSource;
// import DxDataGridComponent = DevExpress.data.Da
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
// import DataSource from 'devextreme/data/data_source';

@Component({
    template: ''
})
export class BaseDataGrid {
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

    dataSource: any;
    allowedPageSizes = [5, 10, 20, 50];

    isRefresh: boolean = false;

    constructor() { }

    refresh() {
        this.isRefresh = true;
        if (this.dataGrid.dataSource instanceof DataSource && this.dataGrid.instance?.getDataSource()) {
            this.clearSelection();
            this.dataGrid.instance
                .getDataSource()
                .reload()
                .then((response) => {
                    this.isRefresh = false;
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    clear() {
        this.dataGrid.instance.clearFilter();
        this.clearSelection();
    }

    clearFilterSorting(): void {
        this.dataGrid.instance.clearFilter();
        this.dataGrid.instance.clearSorting();
        this.clearSelection();
    }

    clearSelection(): void {
        this.dataGrid.instance.deselectAll();
        this.dataGrid.instance.clearSelection();
    }

    isHaveFilterState(state): boolean {
        let isHaveFilter = false;
        if (state && state.columns) {
            state.columns.map(column => {
                if (typeof column !== 'undefined' && column.hasOwnProperty('filterValue') && column.filterValue) {
                    isHaveFilter = true;
                }
            });
        }
        return isHaveFilter;
    }

    emptyTablePromise() {
        return new Promise((resolve, reject) => {
            resolve({
                data: [],
                totalCount: 0
            });
        });
    }

    onToolbarPreparing(e): void {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'titleTemplate',
                locateInMenu: 'never',
            },
            {
                location: 'after',
                template: 'refresh',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear-all',
                locateInMenu: 'auto',
            },
        );
    }
}

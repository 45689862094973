import { Injectable } from '@angular/core';
import { BaseDataGridService } from '../../../shared/utils/base-data-grid.service';
import {environment} from '../../../environments/environment';
import {HttpClient } from '@angular/common/http';
import {map} from "rxjs/operators";

const URL = environment?.api_service + environment?.v1 + '/management/Payment/CardLimits';
const RESOURCE = 'CardLimits';

@Injectable({
    providedIn: "root",
})
export class CardLimitService extends BaseDataGridService {
    resource: string = RESOURCE;
    url: string = URL;

    constructor(
        public _httpClient: HttpClient,
    ) {
        super(URL, RESOURCE, _httpClient);
    }

    changeLimit(data: any) {
        return this._httpClient.post(this.url + '/Change', data)
            .pipe(
                map((response: any) => {
                    return {
                        data: response.data && response.data.items ? response.data.items : [],
                        totalCount: response.data && response.data.totalCount ? response.data.totalCount : 0,
                    };
                })
            );
    }
}

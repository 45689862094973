import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {map} from "rxjs/operators";

const URL = environment.api_service + environment.v1 + '/management/Identity/UserVerifications';
const RESOURCE = 'Verifications';

@Injectable({
    providedIn: "root",
})
export class VerificationService {
    resource: string = RESOURCE;
    url: string = URL;
    verificationInfoSubject = new BehaviorSubject<any>(null);
    urlUsers = environment?.api_service + environment?.v1 + '/management/Identity/Users';
    verificationUrl = environment?.api_service + environment?.v1 + '/management/Identity/UserVerifications/Start';
    constructor(
        public _httpClient: HttpClient,
    ) {
        // super(URL, RESOURCE, _httpClient);
    }

    // getEnum(enumName: string) {
    //     const httpParams = new HttpParams().set('EnumName', enumName);
    //     return this._httpClient.get(this.urlEnum, {params: httpParams})
    // }

    startVerification(data: { userId: string,  }) {
        return this._httpClient.post(this.verificationUrl, data);
    }

    searchUsers(value: string) {
        const httpParams = new HttpParams().set('filters', 'email@=' + value);
        return this._httpClient.get(this.urlUsers, {params: httpParams});
    }

    approve(data: {id: string, arguments: string}) {
        return this._httpClient.post(this.url + '/Approve', data);
    }

    approveDocument(data: {id: string, arguments: string}) {
        return this._httpClient.post(this.url + '/DocumentUpdate/Approve', data);
    }

    deny(data: {id: string, arguments: string}) {
        return this._httpClient.post(this.url + '/Deny', data);
    }

    denyDocument(data: {id: string, arguments: string}) {
        return this._httpClient.post(this.url + '/DocumentUpdate/Deny', data);
    }

    getDetailsById(id: string) {
        const httpParams = new HttpParams().set('Id', id);
        return this._httpClient.get(this.url + '/Details', {params: httpParams}).pipe(
            map((response: any) => {
                this.verificationInfoSubject.next(response.data);
                return response;
            }),
        );
    }
}

<mat-toolbar color="primary" mat-dialog-title fxLayout fxLayoutAlign="center center">
    <span>{{ 'CHANGE_LIMIT_CARD' | transloco | uppercase }}</span>
</mat-toolbar>

<mat-dialog-content class="p-0 m-0">
    <div [formGroup]="form" class="max-width-class flex flex-col gap-2">
        <div class="flex flex-col px-6 pt-4">

            <mat-form-field class="fuse-mat-bold w-full fuse-mat-dense">
                <mat-label>{{ 'LIMIT' | transloco }}</mat-label>
                <input matInput required type="number" formControlName="limit">
                <mat-error *ngIf="form.get('limit')?.hasError('required')">{{ 'REQUIRED_FIELD' | transloco }}</mat-error>
                <mat-error *ngIf="form.get('limit')?.hasError('min')">{{ 'MIN_VALUE' | transloco:{min: 1} }}</mat-error>
            </mat-form-field>

            <mat-form-field class="fuse-mat-bold w-full fuse-mat-dense">
                <mat-label>{{ 'DESCRIPTION' | transloco }}</mat-label>
                <textarea matInput required formControlName="description" [rows]="3"></textarea>
                <mat-error *ngIf="form.get('description')?.hasError('required')">{{ 'REQUIRED_FIELD' | transloco }}</mat-error>
            </mat-form-field>

        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="items-center justify-center">
    <button mat-raised-button [disabled]="form.invalid || isSaving" [color]="'primary'" (click)="change()">
        <div class="flex flex-row items-center gap-3">
            <span>{{'CHANGE' | transloco}}</span>
            <mat-progress-spinner *ngIf="isSaving" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
    </button>
    <button mat-raised-button mat-dialog-close="close" [disabled]="isSaving">{{'CANCEL' | transloco}}</button>
</mat-dialog-actions>

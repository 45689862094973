import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JsonsPrintComponent } from '../jsons-print/jsons-print.component';

@Component({
  selector: 'app-load-modal',
  templateUrl: './load-modal.component.html',
  styleUrls: ['./load-modal.component.scss']
})
export class LoadModalComponent implements OnInit {
  @Input() id: string = "";
  @Input() permission: string = "";
  @Input() buttonColor: string = "accent";
  @Input() isTable: boolean = true;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {

  }

  loadModal() {
    this.dialog.open(JsonsPrintComponent, {
      width: 'auto',
      minWidth: '60vw',
      data: {
        title: "EXTERNAL_INTERACTIONS",
        id: this.id,
      },
    });
  }

}

import {AfterViewInit, Component, OnInit, Input, OnDestroy} from '@angular/core';
import { BaseDataGrid } from '../../../../shared/utils/base-data-grid';
import DataSource from 'devextreme/data/data_source';
import { TranslocoService } from '@ngneat/transloco';
import { PaymentCardService } from "../../../../modules/payment-card/services/payment-card.service";
import {Subscription} from "rxjs";
import {UserService} from "../../../../modules/user/services/user.service";
import {UserPaymentCardService} from "../../../../modules/user/services/user-payment-card.service";

@Component({
    selector: 'app-payment-card-table',
    templateUrl: './card-table.component.html'
})
export class CardTableComponent extends BaseDataGrid implements OnInit, AfterViewInit, OnDestroy {
    @Input() permission = 'GetPaymentCardsQuery';
    pageSize: number = 10;
    infoSubscription: Subscription;
    user: any;

    constructor(
        private paymentCardService: PaymentCardService,
        private userPaymentCardService: UserPaymentCardService,
        private _translocoService: TranslocoService,
        private userService: UserService,
    ) {
        super();
    }

    ngOnInit(): void {
        switch (this.permission) {
            case 'GetPaymentCardsQuery': {
                this.dataSource = new DataSource({
                    key: "id",
                    load: (loadOptions: any) =>
                        // this.loadFetchPaymentCardsPanel(loadOptions)
                        this.paymentCardService.getAll(loadOptions, this.isRefresh)
                });
                break;
            }
            //  'GetPaymentCardsQuery-User'
            default: {
                this.infoSubscription = this.userService.userInfoSubject.subscribe(user => {
                    this.user = user;
                });
                this.dataSource = new DataSource({
                    key: "id",
                    load: (loadOptions: any) =>
                        this.userPaymentCardService.getAll(loadOptions, this.isRefresh, [], {'userId==': this.user.id})
                });
                break;
            }
        }
    }

    ngAfterViewInit(): void {
        switch (this.permission) {
            case 'GetPaymentCardsQuery': {
                this.paymentCardService.setDataGrid(this.dataGrid);
                break;
            }
            //  'GetPaymentCardsQuery-User'
            default: {
                this.userPaymentCardService.setDataGrid(this.dataGrid);
                break;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.permission === 'GetPaymentCardsQuery-User') {
            this.infoSubscription.unsubscribe();
        }
    }

    onToolbarPreparing(e): void {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'titleTemplate',
                locateInMenu: 'never',
            },
            {
                location: 'after',
                template: 'refresh',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear-all',
                locateInMenu: 'auto',
            },
        );
    }
}

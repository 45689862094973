<dx-data-grid [dataSource]="dataSource" [remoteOperations]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

    <div *dxTemplate="let data of 'titleTemplate'">
        <span class="font-bold text-2xl text-accent">{{ 'CARD_LIMITS' | transloco }}</span>
    </div>
    <div *dxTemplate="let data of 'refresh'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="refresh()"
                matTooltip="{{'REFRESH' | transloco}}">
            <mat-icon>sync</mat-icon>
        </button>
    </div>
    <div *dxTemplate="let data of 'clear'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="clear()"
                matTooltip="{{'CLEAN_FILTER' | transloco}}">
            <mat-icon>filter_alt_off</mat-icon>
        </button>
    </div>
    <div *dxTemplate="let data of 'clear-all'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="clearFilterSorting()"
                matTooltip="{{'CLEAN_FILTER_SORTING' | transloco}}">
            <mat-icon>restore_page</mat-icon>
        </button>
    </div>

    <dxi-column dataField="actions" alignment="center" [width]="140" caption="{{'ACTIONS' | transloco}}"
                [allowSorting]=false cellTemplate="actions">
    </dxi-column>
    <div *dxTemplate="let data of 'actions'">
        <button mat-icon-button color="accent" (click)="editLimitCard(data?.data)" *hasPermission="'ChangeUserCardLimitCommand'"
                matTooltip="{{'EDIT_LIMIT' | transloco}}">
            <mat-icon>edit</mat-icon>
        </button>
    </div>

    <dxi-column dataField="id" [width]="110" dataType="string" cellTemplate="cellTemplateIds"
                [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'">
        <app-id-table-template [id]="cell.text" [isTable]="false"></app-id-table-template>
    </div>

    <dxi-column dataField="userId" alignment="center" caption="{{'USER_ID' | transloco}}" [width]="110" dataType="string" cellTemplate="cellTemplateIds"
                [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'">
        <app-id-table-template [id]="cell.text" [isTable]="false"></app-id-table-template>
    </div>
    <dxi-column dataField="userEmail" caption="{{'USER_EMAIL' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="paymentClientId" alignment="center" caption="{{'PAYMENT_CLIENT_ID' | transloco}}" dataType="string"
                [filterOperations]="['=']" cellTemplate="cellTemplatePaymentClientName"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplatePaymentClientName'">
        <app-id-table-template [id]="cell.text" [isTable]="false"></app-id-table-template>
    </div>
    <dxi-column dataField="paymentClientName" caption="{{'PAYMENT_CLIENT_NAME' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="limit" caption="{{'CARD_LIMITS' | transloco}}" alignment="center" dataType="number"></dxi-column>

    <dxi-column dataField="createdAt" sortOrder="desc" caption="{{'CREATED' | transloco}}" dataType="datetime"
                cellTemplate="dateCreatedTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'dateCreatedTemplate'">
        {{ cell.data.createdAt | date: 'd MMM yyyy H:mm:ss a' }}
    </div>
    <dxi-column dataField="updatedAt" caption="{{'UPDATED' | transloco}}" dataType="datetime"
                cellTemplate="dateUpdatedTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'dateUpdatedTemplate'">
        {{ cell.data.updatedAt | date: 'd MMM yyyy H:mm:ss a' }}
    </div>

    <dxo-paging [pageSize]="pageSize"></dxo-paging>
    <dxo-pager visible="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
               [showNavigationButtons]="true" [showInfo]="true"
               infoText="{{'PAGE' | transloco}} {0} {{'OF' | transloco}} {1} ({2} {{'RESULTS' | transloco}})">
    </dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-state-storing [enabled]="true"></dxo-state-storing>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
</dx-data-grid>

import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { BaseDataGrid } from '../../../../shared/utils/base-data-grid';
import DataSource from 'devextreme/data/data_source';
import { TranslocoService } from '@ngneat/transloco';
import {WalletOperationService} from "../../../../modules/wallet-operation/services/wallet-operation.service";
import {Subscription} from "rxjs";
import {UserService} from "../../../../modules/user/services/user.service";
import {UserWalletOperationService} from "../../../../modules/user/services/user-wallet-operation.service";
import {AuthService} from "../../../../core/auth/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-user-wallet-operation-table',
    templateUrl: './wallet-operation-table.component.html'
})
export class WalletOperationTableComponent extends BaseDataGrid implements OnInit, AfterViewInit, OnDestroy {
    @Input() permission = 'GetKwalletOperationsQuery';
    pageSize: number = 10;
    infoSubscription: Subscription;
    user: any;
    isHaveUserDetailsPermission = false;

    constructor(
        private walletOperationService: WalletOperationService,
        private userWalletOperationService: UserWalletOperationService,
        private _translocoService: TranslocoService,
        private userService: UserService,
        private authService: AuthService,
        private router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        switch (this.permission) {
            case 'GetKwalletOperationsQuery': {
                this.setPermissionUserDetails();
                this.dataSource = new DataSource({
                    key: "id",
                    load: (loadOptions: any) =>
                        this.walletOperationService.getAll(loadOptions, this.isRefresh, ['amount']),
                });
                break;
            }
            //  'GetKwalletOperationsQuery-User'
            default: {
                this.infoSubscription = this.userService.userInfoSubject.subscribe(user => {
                    this.user = user;
                });
                this.dataSource = new DataSource({
                    key: "id",
                    load: (loadOptions: any) =>
                        this.userWalletOperationService.getAll(loadOptions, this.isRefresh, ['amount'], null, {key: 'userId', value: this.user.id})
                });
                break;
            }
        }
    }

    navigateToUserDetails(cell: any): void {
        if (this.isHaveUserDetailsPermission) {
            this.router.navigate(['/users', cell.row.data.userId, 'info']);
        }
    }

    setPermissionUserDetails(): void {
        this.isHaveUserDetailsPermission = this.authService.hasPermission('GetUsersQuery');
    }

    ngAfterViewInit(): void {
        switch (this.permission) {
            case 'GetKwalletOperationsQuery': {
                this.walletOperationService.setDataGrid(this.dataGrid);
                break;
            }
            //  'GetKwalletOperationsQuery-User'
            default: {
                this.userWalletOperationService.setDataGrid(this.dataGrid);
                break;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.permission === 'GetKwalletOperationsQuery-User') {
            this.infoSubscription.unsubscribe();
        }
    }

    onToolbarPreparing(e): void {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'titleTemplate',
                locateInMenu: 'never',
            },
            {
                location: 'after',
                template: 'refresh',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear-all',
                locateInMenu: 'auto',
            },
        );
    }
}

import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {environment} from "../../environments/environment";
import {AuthService} from "../../core/auth/auth.service";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor(private authService: AuthService) {
    }

    handleError(error: Error): any {
        if (!environment.isLocal) {
            Sentry.setUser({
                username: this.authService.keyCurrentUser?.email
            });
            Sentry.captureException(error);
        } else {
            throw error;
        }
    }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-text-dialog',
    template: `
      <h1 mat-dialog-title>{{"MESSAGE" | transloco}}</h1>
      <div mat-dialog-content>
          <p>{{ data.message }}</p>
      </div>
      <div mat-dialog-actions>
          <button mat-button (click)="onClose()">{{"CLOSE" | transloco}}</button>
      </div>
  `,
    styles: [`
    mat-dialog-content {
      white-space: pre-wrap;
    }
  `]
})
export class TextDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<TextDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { message: string }
    ) {}

    onClose(): void {
        this.dialogRef.close();
    }
}

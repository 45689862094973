import { Injectable } from "@angular/core";
import { BaseDataGridService } from '../../../shared/utils/base-data-grid.service';
import {environment} from "../../../environments/environment";
import {HttpClient } from "@angular/common/http";

// const URL = environment.api_service + environment.v1 + '/management/Payment/Wallet/Operations';
const URL = environment.api_service + environment.v1 + '/management/Payment/Wallet/Operations2/User';
const RESOURCE = 'UserWalletOperations';

@Injectable({
    providedIn: "root",
})
export class UserWalletOperationService extends BaseDataGridService {
    resource: string = RESOURCE;
    url: string = URL;

    constructor(
        public _httpClient: HttpClient,
    ) {
        super(URL, RESOURCE, _httpClient);
    }
}

import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            // Asegurémonos de tener dos dígitos para el día y el mes
            const dayStr = day < 10 ? '0' + day : day.toString();
            const monthStr = month < 10 ? '0' + month : month.toString();

            return `${dayStr}/${monthStr}/${year}`;
        }

        return date.toDateString();
    }
}

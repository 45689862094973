import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslocoService
    ) {}

    success(message: string = 'success'): void {
        this.showSuccessNotification(this.translateService.translate(message));
    }

    error(message: string): void {
        if (!message || message.length === 0) {
            this.showErrorNotification(this.translateService.translate('error'));
        } else {
            this.showErrorNotification(message);
        }
    }

    notificationComponent(componentNotification: any, durationMilliseconds: number = 0) {
        this.snackBar.openFromComponent(componentNotification, {
            duration: durationMilliseconds,
            verticalPosition: 'top'
        });
    }

    private showErrorNotification(message: string) {
        this.snackBar.open(message, "Ok", {
            duration: 5000,
            panelClass: "error-notification-snacbar",
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
        });
    }

    private showSuccessNotification(message: string) {
        this.snackBar.open(message, "Ok", {
            duration: 5000,
            panelClass: "success-notification",
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
        });
    }
}

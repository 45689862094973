import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { AuthService } from "../../../../../../app/core/auth/auth.service";

@Component({
    selector       : 'fuse-vertical-navigation-group-item',
    templateUrl    : './group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseVerticalNavigationGroupItemComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_autoCollapse: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() autoCollapse: boolean;
    @Input() item: FuseNavigationItem;
    @Input() name: string;

    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _authService: AuthService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the parent navigation component
        this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    hasPermission(permissionKey: string): boolean {
        let hasPermission = false;
        if (permissionKey) {
            hasPermission = this._authService.hasPermission(permissionKey);
        } else {
            hasPermission = true;
        }
        return hasPermission;
    }

    hasPermissionCollapsable(children: FuseNavigationItem[]): boolean {
        let hasPermission = false;
        if (children.some(item =>
            (item.type !== 'collapsable' && !item.permissionKey) ||
            (item.type !== 'collapsable' && item.permissionKey && this._authService.hasPermission(item.permissionKey)) ||
            (item.type === 'collapsable' && this.hasPermissionCollapsable(item.children))
        )) {
            hasPermission = true;
        }
        return hasPermission;
    }

    hasPermissionGroup(children: any[]): boolean {
        let hasPermission = false;
        if (children.some(item =>
            ((!item.permissionKey && item.type === 'basic') ||
                ((item.permissionKey && item.type === 'basic') && this._authService.hasPermission(item.permissionKey))) ||
            (item.type === 'collapsable' && this.hasPermissionCollapsable(item.children))
        )) {
            hasPermission = true;
        }
        return hasPermission;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }
}

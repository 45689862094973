import { Component, OnInit, Inject } from "@angular/core";
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {NotificationService} from "../../services/notifications.service";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: "app-confirm-dialog-component",
    templateUrl: "./confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
    isLoading: boolean = false;
    title: string;
    action: any;
    message: string;
    messageBlack: boolean;
    subMessage: string;
    confirmAction: string;
    cancelAction: string;
    notificationSuccess: string;
    isWarnBotton: boolean;
    multipleAction = false;
    textCenterTitle = false;
    notificationFailMultiple: string;

    controlForm: FormGroup;
    faSpinner = faSpinner;
    isDownloadFile: boolean = false;
    showConfirm: boolean;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private notificationService: NotificationService,
        private _formBuilder: FormBuilder,
    ) {
        this.title = data.title;
        this.action = data.action;
        this.isDownloadFile = !!data.isDownloadFile
        this.multipleAction = data.multipleAction;
        this.message = data.message ? data.message : "";
        this.messageBlack = data.messageBlack ? true : false;
        this.textCenterTitle = data.textCenterTitle ? true : false;
        this.subMessage = data.subMessage ? data.subMessage : "";
        this.confirmAction = data.confirmAction ? data.confirmAction : "YES";
        this.cancelAction = data.cancelAction ? data.cancelAction : "NO";
        this.isWarnBotton = data.isWarnBotton ? data.isWarnBotton : false;
        this.showConfirm = data.showConfirm ?? true
        this.notificationSuccess = data.notificationSuccess
            ? data.notificationSuccess
            : "ACTION_COMPLETED_SUCCESSFULLY";
        this.notificationFailMultiple = data.notificationFailMultiple;
    }

    ngOnInit(): void {
        this.controlForm = this._formBuilder.group({
        });
    }

    onSubmit(): void {
        this.isLoading = true;
        this.action.subscribe((response: any) => {
            if (this.multipleAction) {
                const responseArray: any[] = Object.values(response);
                if (responseArray.reduce((acc, cur) => acc.success && cur.success)) {
                    this.notificationService.success(this.notificationSuccess);
                } else {
                    this.notificationService.error(this.notificationFailMultiple);
                }
            } else {
                if (!this.isDownloadFile) {
                    if (response.success) {
                        this.notificationService.success(this.notificationSuccess);
                    } else {
                        this.notificationService.error(response.error.message);
                    }
                }
            }


            this.isLoading = false;
            this.dialogRef.close(response);
        }, (error: any) => {
            this.isLoading = false;
            this.notificationService.error(error.error.message);
        });
    }
    close(): void {
        this.dialogRef.close(null);
    }
}

import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable, of, retry, tap} from 'rxjs';
import {CacheResolverService} from "../services/cache.service";
import {environment} from "../../environments/environment";

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    cacheResources = [
        '/api/v1/discovery/enum',
        '/api/v1/management/Orders/StoreOrder/Stores',
        '/api/v1/management/Orders/StoreOrder/ShippingMethods',
        '/api/v1/Management/Inventory/productCategories/Path',
    ];
    constructor(
        private cacheResolver: CacheResolverService,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.method !== 'GET') {
            return this.sendRequest(request, next);
        }

        const isHaveCache = this.hasCacheResource(request);
        if (isHaveCache) {
            let cachedData = this.cacheResolver.get(request.urlWithParams);
            return cachedData ? of(cachedData) : this.sendRequest(request, next);
        } else {
            return this.sendRequest(request, next);
        }
    }

    private sendRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap((event) => {
                if (event instanceof HttpResponse && this.hasCacheResource(request)) {
                    this.cacheResolver.set(request.urlWithParams, event, environment.cacheExpirationTime);
                }
            })
        );
    }

    hasCacheResource(request: HttpRequest<unknown>): boolean {
        return this.cacheResources.some((resource: string) => request.url.includes(resource));
    }

}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {BehaviorSubject, catchError, empty, Observable, switchMap, take, throwError} from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import {TranslocoService} from "@ngneat/transloco";
import {environment} from "../../environments/environment";
import {filter} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _translocoService: TranslocoService,
        private _router: Router
    ) { }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
    // && !AuthUtils.isTokenExpired(this._authService.keyAccessToken)
        if ( this._authService.keyAccessToken && !newReq.url.includes('s3.amazonaws.com'))
        {
            const language = JSON.parse(localStorage.getItem("language"));
            newReq = req.clone({
                headers: req.headers
                    .set('Authorization', 'Bearer ' + this._authService.keyAccessToken)
                    .set('Accept-Language', language ? language : this._translocoService.getActiveLang())
                    .set('X-Payment-Client', environment?.X_Payment_Client)
            });
        }
        // Response
        return next.handle(newReq).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401
                && (error.headers.get('reason') === 'Invalid/Expired Token' || error.headers.get('reason') === 'ExpiredToken')) {
                return this.handleAuthenticationError(newReq, next);
            } else {
                return throwError(error);
            }
        }));
    }

    private handleAuthenticationError(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this._authService.refreshToken().pipe(
                switchMap((isToken: boolean) => {
                    this.isRefreshing = false;
                    const access_token = this._authService.keyAccessToken;
                    this.refreshTokenSubject.next(access_token!);
                    return next.handle(this.addToken(request, access_token!));
                }),
                catchError(() => {
                    // todo notificar que ha expirado la session
                    this.isRefreshing = false;
                    this._authService.logout();
                    this._router.navigate(['/sign-in']);
                    return empty();
                }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

}

<dx-data-grid [dataSource]="dataSource" [remoteOperations]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

    <div *dxTemplate="let data of 'titleTemplate'">
        <span class="font-bold text-2xl text-accent">{{ 'FAILED_PAYMENT_ATTEMPTS' | transloco }}</span>
    </div>
    <div *dxTemplate="let data of 'refresh'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="refresh()"
                matTooltip="{{'REFRESH' | transloco}}">
            <mat-icon>sync</mat-icon>
        </button>
    </div>
    <div *dxTemplate="let data of 'clear'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="clear()"
                matTooltip="{{'CLEAN_FILTER' | transloco}}">
            <mat-icon>filter_alt_off</mat-icon>
        </button>
    </div>
    <div *dxTemplate="let data of 'clear-all'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="clearFilterSorting()"
                matTooltip="{{'CLEAN_FILTER_SORTING' | transloco}}">
            <mat-icon>restore_page</mat-icon>
        </button>
    </div>

    <dxi-column dataField="id" [width]="110" dataType="string" cellTemplate="cellTemplateIds"
                [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'">
        <app-id-table-template [id]="cell.text"></app-id-table-template>
    </div>

    <dxi-column *ngIf="permission == 'GetFailedCardsQuery'" dataField="userId" caption="{{'USER_ID' | transloco}}" [width]="110" dataType="string" cellTemplate="cellTemplateIds"
                [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'">
        <app-id-table-template [id]="cell.text"></app-id-table-template>
    </div>

    <dxi-column *ngIf="permission == 'GetFailedCardsQuery'" dataField="userEmail" caption="{{'USER_EMAIL' | transloco}}" dataType="string"></dxi-column>

<!--    <dxi-column dataField="merchantId" caption="{{'MERCHANT' | transloco}}" [width]="110" dataType="string" cellTemplate="cellTemplateMerchantIds"-->
<!--                [filterOperations]="['=']"></dxi-column>-->
<!--    <div *dxTemplate="let cell of 'cellTemplateMerchantIds'">-->
<!--        <app-id-table-template [id]="cell.text"></app-id-table-template>-->
<!--    </div>-->

    <dxi-column dataField="cardComposedId" caption="{{'CARD_COMPOSE_ID' | transloco}}" [width]="110" dataType="string" cellTemplate="cellTemplateCardComposedIds"
                [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateCardComposedIds'">
        <app-id-table-template [id]="cell.text"></app-id-table-template>
    </div>

    <dxi-column dataField="merchantResponse" alignment="center" caption="{{'MERCHANT' | transloco}}" dataType="string"
                [filterOperations]="[]" cellTemplate="cellTemplateMerchantResponse"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateMerchantResponse'" class="flex flex-row justify-center">
        <app-id-table-template [isTable]="false" [id]="cell.data.merchantId"></app-id-table-template>
        <button mat-icon-button color="accent" (click)="callJsonViewer(cell.data.merchantResponse, false, 'MERCHANT_RESPONSE')"
                matTooltip="{{ 'MERCHANT_RESPONSE' | transloco }}" [matTooltipPosition]="'above'" [ngClass]="{'hidden': !cell.data.merchantResponse}">
            <mat-icon>visibility</mat-icon>
        </button>
    </div>

    <dxi-column dataField="lastFour" caption="{{'LAST_FOUR' | transloco}}" alignment="center" dataType="string"></dxi-column>

    <dxi-column dataField="expirationMonth" caption="{{'EXPIRATION_MONTH' | transloco}}" alignment="center" dataType="number"></dxi-column>
    <dxi-column dataField="expirationYear" caption="{{'EXPIRATION_YEAR' | transloco}}" alignment="center" dataType="number"></dxi-column>

<!--    <dxi-column dataField="type" caption="{{'TYPE' | transloco}}" dataType="string"></dxi-column>-->
<!--    <dxi-column dataField="scheme" caption="{{'SCHEME' | transloco}}" dataType="string"></dxi-column>-->
<!--    <dxi-column dataField="bank" caption="{{'BANCK' | transloco}}" dataType="string"></dxi-column>-->

<!--    <dxi-column dataField="firstName" caption="{{'FIRST_NAME' | transloco}}" dataType="string"></dxi-column>-->
<!--    <dxi-column dataField="lastName" caption="{{'LAST_NAME' | transloco}}" dataType="string"></dxi-column>-->

<!--    <dxi-column dataField="addressLine" caption="{{'ADDRESS_LINE' | transloco}}" dataType="string"></dxi-column>-->
<!--    <dxi-column dataField="state" caption="{{'STATE' | transloco}}" dataType="string"></dxi-column>-->
<!--    <dxi-column dataField="city" caption="{{'CITY' | transloco}}" dataType="string"></dxi-column>-->

<!--    <dxi-column dataField="phoneNumber" caption="{{'PHONE_NUMBER' | transloco}}" dataType="string"></dxi-column>-->
<!--    <dxi-column dataField="fullPhone" caption="{{'FULL_PHONE' | transloco}}" dataType="string"></dxi-column>-->

    <dxi-column dataField="createdAt" sortOrder="desc" caption="{{'CREATED' | transloco}}" dataType="datetime"
                cellTemplate="dateCreatedTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'dateCreatedTemplate'">
        {{ cell.data.createdAt | date: 'd MMM yyyy H:mm:ss a' }}
    </div>
    <dxi-column dataField="updatedAt" caption="{{'UPDATED' | transloco}}" dataType="datetime"
                cellTemplate="dateUpdatedTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'dateUpdatedTemplate'">
        {{ cell.data.updatedAt | date: 'd MMM yyyy H:mm:ss a' }}
    </div>

    <dxo-paging [pageSize]="pageSize"></dxo-paging>
    <dxo-pager visible="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
               [showNavigationButtons]="true" [showInfo]="true"
               infoText="{{'PAGE' | transloco}} {0} {{'OF' | transloco}} {1} ({2} {{'RESULTS' | transloco}})">
    </dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-state-storing [enabled]="true"></dxo-state-storing>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
</dx-data-grid>

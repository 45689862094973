<mat-toolbar color="primary" mat-dialog-title>
    <span>{{ 'START_VERIFICATION' | transloco | uppercase}}</span>
</mat-toolbar>

<mat-dialog-content [formGroup]="form" class="flex flex-col gap-1 pt-5" [ngClass]="{'opacity-50 pointer-events-none': isLoading}">

    <mat-form-field class="fuse-mat-bold flex flex-1 fuse-mat-dense">
        <mat-label>{{ 'USER' | transloco }}</mat-label>
        <input matInput required [matAutocomplete]="auto" formControlName='userId'>
<!--        <fa-icon *ngIf="isLoadingGetWalletByUser" class="relative flex justify-center items-center animate-spin rounded-full text-accent-800 text-2xl"-->
<!--                 [icon]="faSpinner">-->
<!--        </fa-icon>-->
<!--        <span *ngIf="isActiveWallet && !isBlockedWallet" matSuffix>{{ balance | currency:'USD' }}</span>-->
        <mat-hint>{{ 'USER_SEARCH_BY_EMAIL' | transloco }}</mat-hint>
        <mat-error *ngIf="form.get('userId').hasError('required')">
            {{ 'REQUIRED_FIELD' | transloco }}
        </mat-error>
        <mat-error *ngIf="form.get('userId').hasError('activeInvalid')">
            {{ 'THE_WALLET_IS_NOT_ACTIVE' | transloco }}
        </mat-error>
        <mat-error *ngIf="form.get('userId').hasError('blocked')">
            {{ 'THE_WALLET_IS_BLOCKED' | transloco }}
        </mat-error>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUserFn">
        <mat-option *ngIf="isLoadingUser" class="is-loading">
            <fa-icon class="relative flex justify-center items-center animate-spin rounded-full text-accent-800 text-3xl"
                     [icon]="faSpinner">
            </fa-icon>
        </mat-option>
        <ng-container *ngIf="!isLoadingUser">
            <mat-option *ngFor="let user of filteredUsers" [value]="user">
                <small>{{user.email}}</small>
            </mat-option>
        </ng-container>
    </mat-autocomplete>

    <mat-form-field class="fuse-mat-bold w-full fuse-mat-dense">
        <mat-label>{{ 'FIRST_NAME' | transloco }}</mat-label>
        <input matInput required formControlName="firstname">
        <mat-error *ngIf="form.get('firstname')?.hasError('required')">{{ 'REQUIRED_FIELD' | transloco }}</mat-error>
    </mat-form-field>

    <mat-form-field class="fuse-mat-bold w-full fuse-mat-dense">
        <mat-label>{{ 'LAST_NAME' | transloco }}</mat-label>
        <input matInput required formControlName="lastname">
        <mat-error *ngIf="form.get('lastname')?.hasError('required')">{{ 'REQUIRED_FIELD' | transloco }}</mat-error>
    </mat-form-field>

    <div class="flex gap-1" *ngIf="onfidoUrl">
        <button mat-icon-button [color]="'accent'" (click)="callServiceToCopy()" matTooltip="{{ 'COPY' | transloco }}"
                [matTooltipPosition]="'above'">
            <mat-icon class="s-20">content_copy</mat-icon>
        </button>
        <div matTooltip="{{'OPEN_IN_NEW_TAB' | transloco}}" [matTooltipPosition]="'above'" class="text-blue-500 underline cursor-pointer" (click)="open()">{{onfidoUrl}}</div>
    </div>

</mat-dialog-content>

<mat-dialog-actions class="flex flex-row items-center justify-center">
    <button mat-raised-button [disabled]="form.invalid || !isObject() || isLoading" (click)="onSubmit()" color="primary" [ngClass]="{'pr-2': isLoading}">
        <div class="flex items-center">
            <div>{{ 'START' | transloco}}</div>
            <fa-icon *ngIf="isLoading"
                     class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
        </div>
    </button>
    <button [disabled]="isLoading" mat-raised-button mat-dialog-close="close">{{'CANCEL' | transloco}}</button>
</mat-dialog-actions>

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { enableProdMode } from "@angular/core";
import { environment } from './app/environments/environment';

import * as Sentry from '@sentry/angular';
import * as SentryReplay from "@sentry/angular-ivy";

if (environment.production) {
    enableProdMode();
}

if (!environment.isLocal) {
    Sentry.init({
        dsn: 'https://34b8cdd674f4ef1f830787557a21cee5@o4504119300063232.ingest.us.sentry.io/4507407983247360',
        integrations: [
            SentryReplay.replayIntegration({
                maskAllInputs: true,
                blockAllMedia: true,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: environment.name
    });
}

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));

import { Injectable } from "@angular/core";
import { BaseDataGridService } from '../../../shared/utils/base-data-grid.service';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";

const URL = environment.api_service + environment.v1 + '/management/LaNave/LaNaveOrders';
const DETAILS_URL = environment.api_service + environment.v1 + '/management/LaNave/LaNaveOrders/Details';
const RESOURCE = 'LaNaveOrders';
@Injectable({
  providedIn: 'root'
})
export class LaNaveService extends BaseDataGridService {
  resource: string = RESOURCE;
  url: string = URL;
  urlEnum: string = environment.api_service + environment.v1 + '/discovery/enum';

  constructor(
    public _httpClient: HttpClient,
  ) {
    super(URL, RESOURCE, _httpClient);
  }

  getEnum(enumName: string) {
    const httpParams = new HttpParams().set('EnumName', enumName);
    return this._httpClient.get(this.urlEnum, { params: httpParams })
  }

  get() {
    return this._httpClient.get(this.url);
  }

  add(data: any) {
    return this._httpClient.post(this.url, data);
  }

  getDetails(id: string) {
    const httpParams = new HttpParams().set('Id', id);
    return this._httpClient.get(DETAILS_URL, { params: httpParams });
  }

}
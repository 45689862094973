import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NavItem} from "../../interfaces/nav-item.interface";

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
})
export class MenuItemComponent implements OnInit {
    @Input() items: NavItem[];
    @ViewChild('childMenu') public childMenu;

    constructor() {
    }

    ngOnInit() {
    }
}

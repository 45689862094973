import {Component, OnInit, OnDestroy} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {catchError, filter} from "rxjs/operators";
import {AuthService, PermissionsPanel} from "./core/auth/auth.service";
import {forkJoin, of} from "rxjs";
import {Subject, takeUntil} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CONFIG} from "../assets/config";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    destroy$: Subject<boolean> = new Subject<boolean>();
    private readonly config: { version: string };
    /**
     * Constructor
     */
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private _route: ActivatedRoute,
        private _authService: AuthService,
        private _router: Router,
        private _httpClient: HttpClient,
        private _activatedRoute: ActivatedRoute,
    )
    {
        this.config = CONFIG;
        this.verifyVersionChanges();
        // Registrando icono de filter_remove para las tablas
        // this.matIconRegistry.addSvgIcon(
        //     'filter_remove',
        //     this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/filter_remove_icon_138610.svg')
        // );
        // <mat-icon svgIcon="filter_remove"></mat-icon>
    }

    ngOnInit() {
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe( () => {
            const code = this._route.snapshot.queryParams['code'];
            if (code) {
                this._authService.getTokensWithCode( code ).subscribe(async (resp) => {
                    if(resp?.error){
                        await this._authService.signOff();
                        this._authService.logout();
                        this._router.navigate(['/sign-in']);
                    } else {
                        this.getPermissions();
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    verifyVersionChanges() {
        this._router.events.pipe(
            takeUntil(this.destroy$),
            filter((event: any) => event instanceof NavigationEnd)
        ).subscribe((event) => {
            const headers = new HttpHeaders()
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('x-refresh', 'true');
            this._httpClient
                .get<{ version: string }>("/assets/config.json", { headers })
                .pipe(takeUntil(this.destroy$))
                .subscribe(config => {
                    if (config.version !== this.config.version) {
                        location.reload();
                    }
                });
        });
    }

    getPermissions(): void {
        let arrayOfData = [];
        arrayOfData.push(this._authService.getPermissions());
        arrayOfData.push(this._authService.getReportPermissions());
        forkJoin(arrayOfData).subscribe(([permissionsResponse, reportsResponse]: any) => {
            if (reportsResponse && reportsResponse.data && reportsResponse.data.length) {
                const permissions = this._authService.keyPermissionsPanel;
                if (permissions && permissions.length) {
                    let scopeDefinitions: { ScopeId: string, ScopeName: string, Id: string }[] = [];
                    reportsResponse.data.forEach(it => {
                        if (it.scopeDefinitions && it.scopeDefinitions.length) {
                            it.scopeDefinitions.forEach(scope => {
                                let permission: PermissionsPanel = {
                                    canAccess: true,
                                    operationName: 'GetReport' + it.name + scope.name,
                                    accessResource: null,
                                    accessResourceAction: null,
                                    endpoint: null,
                                    httpMethod: null
                                };
                                permissions.push(permission);
                                scopeDefinitions.push({Id: it.id, ScopeName: scope.name, ScopeId: scope.id});
                            });
                        } else {
                            let permission: PermissionsPanel = {
                                canAccess: true,
                                operationName: 'GetReport' + it.name,
                                accessResource: null,
                                accessResourceAction: null,
                                endpoint: null,
                                httpMethod: null
                            };
                            permissions.push(permission);
                            scopeDefinitions.push({ScopeName: it.name, Id: it.id, ScopeId: null});
                        }
                    });
                    this._authService.permissionsPanel.next(permissions);
                    this._authService.keyPermissionsPanel = permissions;
                    this._authService.keyScopeDefinitionsPanel = scopeDefinitions;
                }
            }
            const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/dashboard';
            this._router.navigateByUrl(redirectURL);
        }, async (error) => {
            this._authService.permissionsPanel.next(error);
            await this._authService.signOff();
            this._authService.logout();
            this._router.navigate(['/sign-in']);
        });
    }
}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-show-list-text',
  templateUrl: './show-list-text-dialog.component.html',
})
export class ShowListTextDialogComponent {
    title: string;
    emptyListMessage: string = '';
    isError: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.title = data.title;
        this.emptyListMessage = data.emptyListMessage;
        this.isError = data.isError;
    }
}


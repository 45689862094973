<mat-menu #childMenu="matMenu">
  <span *ngFor="let child of items">
    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item [matMenuTriggerFor]="menu.childMenu">
        <span>{{child.label}}</span>
      </button>
      <app-menu-item #menu [items]="child.children"></app-menu-item>
    </span>
    <span *ngIf="!child.children || child.children.length === 0">
      <button mat-menu-item>
        <span>{{child.label}}</span>
      </button>
    </span>
  </span>
</mat-menu>

import {AfterViewInit, Component, OnInit, Input} from '@angular/core';
import { BaseDataGrid } from '../../../../shared/utils/base-data-grid';
import DataSource from 'devextreme/data/data_source';
import {Subscription} from "rxjs";
import {UserService} from "../../../../modules/user/services/user.service";
import {FailedPaymentAttemptService} from "../../../../modules/failed-payment-attempt/services/failed-payment-attempt.service";
import {UserFailedPaymentAttemptsService} from "../../../../modules/user/services/user-failed-payment-attempts.service";
import {JsonViewerComponent} from "../../json-viewer/json-viewer.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-failed-payment-attempt-table',
    templateUrl: './failed-payment-attempt-table.component.html'
})
export class FailedPaymentAttemptTableComponent extends BaseDataGrid implements OnInit, AfterViewInit {
    @Input() permission = 'GetFailedCardsQuery';
    pageSize: number = 10;
    infoSubscription: Subscription;
    user: any;

    constructor(
        private failedPaymentAttempt: FailedPaymentAttemptService,
        private userFailedPaymentAttempt: UserFailedPaymentAttemptsService,
        private userService: UserService,
        public dialog: MatDialog
    ) {
        super();
    }

    callJsonViewer(data: any, isJson: boolean = true, title: string) {
        this.dialog.open(JsonViewerComponent, {
            width: '450px',
            data: {
                title: title,
                data: data,
                isJson: isJson
            },
        });
    }

    ngOnInit(): void {
        switch (this.permission) {
            case 'GetFailedCardsQuery': {
                this.dataSource = new DataSource({
                    key: "id",
                    load: (loadOptions: any) =>
                        // this.loadFetchPaymentCardsPanel(loadOptions)
                        this.failedPaymentAttempt.getAll(loadOptions, this.isRefresh)
                });
                break;
            }
            //  'GetFailedCardsQuery-User'
            default: {
                this.infoSubscription = this.userService.userInfoSubject.subscribe(user => {
                    this.user = user;
                });
                this.dataSource = new DataSource({
                    key: "id",
                    load: (loadOptions: any) =>
                        this.userFailedPaymentAttempt.getAll(loadOptions, this.isRefresh, [], {'userId==': this.user.id})
                });
                break;
            }
        }
    }

    ngAfterViewInit(): void {
        switch (this.permission) {
            case 'GetFailedCardsQuery': {
                this.failedPaymentAttempt.setDataGrid(this.dataGrid);
                break;
            }
            //  'GetFailedCardsQuery-User'
            default: {
                this.userFailedPaymentAttempt.setDataGrid(this.dataGrid);
                break;
            }
        }
    }

    onToolbarPreparing(e): void {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'titleTemplate',
                locateInMenu: 'never',
            },
            {
                location: 'after',
                template: 'refresh',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear-all',
                locateInMenu: 'auto',
            },
        );
    }
}

import {Injectable} from '@angular/core';
import {HttpResponse} from "@angular/common/http";
import {StorageService} from "./storage.service";

@Injectable({
    providedIn: 'root'
})
export class CacheResolverService {

    KeyStore = {
        CACHE_DATA: 'cache_data',
    };

    private readonly cache = new Map<string, [Date | null, HttpResponse<any>]>();

    constructor(private storage: StorageService) {
        const cacheInStorage = storage.get(this.KeyStore.CACHE_DATA);
        if (cacheInStorage) {
            this.cache = new Map(cacheInStorage);
        }
    }

    /**
     * Set cache values in a key
     * @param key
     * @param value
     * @param timeToLive
     */
    set(key: string, value: HttpResponse<any>, timeToLive: number | null) {
        if (timeToLive) {
            const expiresIn = new Date();
            expiresIn.setMinutes(expiresIn.getMinutes() + timeToLive);
            this.cache.set(key, [expiresIn, value]);
        } else {
            this.cache.set(key, [null, value]);
        }

        // saving cache data in storage
        this.storage.set(this.KeyStore.CACHE_DATA, this.cache);
    }

    get(key: string): HttpResponse<any> | null {
        const tuple = this.cache.get(key);
        if (!tuple) {
            return null;
        }

        const expiresIn = (tuple[0] instanceof Date) ? tuple[0] : new Date(tuple[0]!);
        const savedData = (tuple[1] instanceof HttpResponse) ? tuple[1] : new HttpResponse<any>(tuple[1]);
        const now = new Date();

        if (expiresIn && expiresIn.getTime() < now.getTime()) {
            this.cache.delete(key);
            return null;
        }
        return savedData;
    }
}

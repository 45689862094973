import { Injectable } from "@angular/core";
import { BaseDataGridService } from '../../../shared/utils/base-data-grid.service';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable} from "rxjs";
import {Result} from "../../../shared/models/result";

const URL = environment.api_service + environment.v1 + '/management/Identity/Users';
const RESOURCE = 'Users';

@Injectable({
    providedIn: "root",
})
export class UserService extends BaseDataGridService {
    resource: string = RESOURCE;
    url: string = URL;
    urlEnum: string = environment.api_service + environment.v1 + '/discovery/enum';
    urlAccessGroups: string = environment.api_service + environment.v1 + '/management/Identity/AccessGroups';
    urlAccessGroupsDropdown: string = environment.api_service + environment.v1 + '/management/Identity/AccessGroupsDropdown';
    urlAccessResources: string = environment.api_service + environment.v1 + '/management/Identity/AccessResources';
    urlUserAccessResources: string = environment.api_service + environment.v1 + '/management/Identity/Users/AccessPolicies';
    urlUserAccessGroups: string = environment.api_service + environment.v1 + '/management/Identity/Users/AccessGroups';
    impersonateUrl: string = environment.api_service + environment.v1 + '/management/Identity/Users/Impersonate';
    urlWallet: string = environment.api_service + environment.v1 + '/management/Payment/Wallet/User';
    urlUserWallets: string = environment.api_service + environment.v1 + '/management/Payment/Wallet/User/All';
    scopeValuesUrl: string = environment.api_service + environment.v1 + '/management/Identity/ScopeValues';
    accessScopeUrl: string = environment.api_service + environment.v1 + '/management/Identity/Users/AccessScoped';
    resetSignInLockExpirationUrl: string = environment.api_service + environment.v1 + '/management/Identity/ResetSignInLockExpiration';
    userInfoSubject = new BehaviorSubject<any>(null);
    constructor(
        public _httpClient: HttpClient,
    ) {
        super(URL, RESOURCE, _httpClient);
    }

    getUserById(id: string) {
        const httpParams = new HttpParams().set('Id', id);
        return this._httpClient.get(this.url + '/Details', {params: httpParams}).pipe(
            map((response: any) => {
                this.userInfoSubject.next(response.data);
                return response;
            }),
        );
    }

    getEnum(enumName: string) {
        const httpParams = new HttpParams().set('EnumName', enumName);
        return this._httpClient.get(this.urlEnum, {params: httpParams});
    }

    getAccessGroups() {
        const httpParams = new HttpParams().set('pageSize', 100);
        return this._httpClient.get(this.urlAccessGroups, {params: httpParams});
    }

    getAccessGroupsDropdown(){
        const httpParams = new HttpParams()
        return this._httpClient.get(this.urlAccessGroupsDropdown, {params: httpParams});
    }

    getAccessResources() {
        return this._httpClient.get(this.urlAccessResources);
    }

    setAccessPolicies(body: any) {
        return this._httpClient.post(this.urlUserAccessResources, body);
    }

    setAccessGroups(body: any) {
        return this._httpClient.post(this.urlUserAccessGroups, body);
    }

    private convertCents(value: number) {
        return Number((value / 100).toFixed(2));
    }

    getScopeValuesByType(typeId: string) {
        const httpParams = new HttpParams().set('Type', typeId);
        return this._httpClient.get(this.scopeValuesUrl, {params: httpParams});
    }

    setAccessScope(data: any) {
        return this._httpClient.post(this.accessScopeUrl, data);
    }

    getAutocompleteByEmail(email: string) {
        const httpParams = new HttpParams().set('Email', email);
        return this._httpClient.get(this.url + "/AutoCompleteByEmail", {params: httpParams});
    }

    getWalletByUserId(id: string, walletTierId: number) {
        const httpParams = new HttpParams()
            .set('WalletTierId', walletTierId)
            .set('UserId', id);
        return this._httpClient.get(this.urlWallet, {params: httpParams}).pipe(
            map((response: any) => {
                response.data.balance = this.convertCents(response.data.balance);
                response.data.pendingBalance = this.convertCents(response.data.pendingBalance);
                response.data.reservedBalance = this.convertCents(response.data.reservedBalance);
                return response;
            }),
        );
    }

    getAllWalletsByUserId(id: string) {
        const httpParams = new HttpParams().set('UserId', id);
        return this._httpClient.get(this.urlUserWallets, {params: httpParams}).pipe(
            map((response: any) => {
                if (response.data) {
                    response.data.map((wallet) => {
                        wallet.balance = this.convertCents(wallet.balance);
                        wallet.pendingBalance = this.convertCents(wallet.pendingBalance);
                        wallet.reservedBalance = this.convertCents(wallet.reservedBalance);
                        return wallet;
                    });
                }
                return response;
            }),
        );
    }

    resetSignInLockExpiration(id: string) : Observable<any> {
        const body = {userId:id};
        return this._httpClient.put(this.resetSignInLockExpirationUrl, body);
    }

    impersonateUser(id: string): Observable<Result<string>> {
      const payload = { userId: id };
      return this._httpClient.post<Result<string>>(this.impersonateUrl, payload);
    }
}



import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-external-ref-text',
  templateUrl: './external-ref-text.component.html',
})
export class ExternalRefTextComponent {
  @Input() text: string = '';
  @Input() route: string = '';

  constructor(private router: Router) {}

  navigateToRoute(): void {
    if (this.route) {
      this.router.navigateByUrl(this.route);
    }
  }
}

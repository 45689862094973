import {AfterViewInit, Directive, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
    selector: '[appDetectTruncate]',
})
export class DetectTruncateDirective implements AfterViewInit{
    @Output() isTruncated = new EventEmitter<boolean>();

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.checkTruncation();
    }

    private checkTruncation() {
        const element = this.el.nativeElement;
        const isTruncated = element.scrollWidth > element.clientWidth;

        if (isTruncated) {
            this.isTruncated.emit(true);
            this.addEllipsis(element);
        } else {
            this.isTruncated.emit(false);
            this.removeEllipsis(element);
        }
    }

    private addEllipsis(element: HTMLElement) {
        const originalText = element.getAttribute('data-original-text') || element.textContent.trim();
        element.setAttribute('data-original-text', originalText);

        let textContent = originalText;
        while (element.scrollWidth > element.clientWidth) {
            textContent = textContent.slice(0, -1);
            element.textContent = textContent + '...';
        }
    }

    private removeEllipsis(element: HTMLElement) {
        const originalText = element.getAttribute('data-original-text');
        if (originalText) {
            element.textContent = originalText;
        }
    }
}

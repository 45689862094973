import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { locale } from 'devextreme/localization';

@NgModule({
    exports  : [
        TranslocoModule
    ],
    providers: [
        {
            // Provide the default Transloco configuration
            provide : TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs      : [
                    {
                        id   : 'en',
                        label: 'English'
                    },
                    // {
                    //     id   : 'tr',
                    //     label: 'Turkish'
                    // },
                    {
                        id   : 'es',
                        label: 'Español'
                    }
                ],
                defaultLang         : 'en',
                fallbackLang        : 'en',
                reRenderOnLangChange: true,
                prodMode            : true
            })
        },
        {
            // Provide the default Transloco loader
            provide : TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            deps      : [TranslocoService],
            useFactory: (translocoService: TranslocoService): any => (): Promise<Translation> => {
                let language = JSON.parse(localStorage.getItem("language"));
                if (!language || language === null) {
                    language = translocoService.getDefaultLang();
                    localStorage.setItem('language', JSON.stringify(language));
                } else {
                    translocoService.setDefaultLang(language);
                }
                locale(language);
                translocoService.setActiveLang(language);
                return translocoService.load(language).toPromise();
            },
            multi     : true
        }
    ]
})
export class TranslocoCoreModule
{
}

import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {JsonEditorOptions,JsonEditorComponent} from "ang-jsoneditor";

@Component({
    selector: 'app-json-viewer-dialog',
    templateUrl: './json-viewer.component.html',
    styleUrls: ['./json-viewer.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class JsonViewerComponent implements OnInit {
    dataJson: string;
    isJson: boolean;
    title: string;
    isViewModified: boolean;
    dataForm: any;
    editorOptions: JsonEditorOptions;
    @ViewChild('editor') editor: JsonEditorComponent;

    constructor(
        private dialogRef: MatDialogRef<JsonViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.title = data.title;
        this.isJson = data.isJson && data.isJson == true;
        this.dataJson = this.isJson ? data.data : JSON.parse(data.data);
        this.isViewModified = data.isViewModified;
        this.editorOptions = new JsonEditorOptions()
        this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
        this.dataForm = this.dataJson;
    }

    ngOnInit(): void {
    }

    // getDataJson(data): any {
    //     let merchantRequest;
    //     try {
    //         merchantRequest = JSON.parse(data);
    //     } catch (e) {
    //         const options = {
    //             textKey: 'value',
    //             attrKey: 'attr',
    //             cdataKey: 'cdata',
    //         };
    //         merchantRequest = this.ngxXmlToJsonService.xmlToJson(data, options);
    //         if (merchantRequest && merchantRequest.html) {
    //             merchantRequest = data;
    //         }
    //     }
    //     return merchantRequest;
    // }

    close(): void {
        this.dialogRef.close(null);
    }

    onJsonChange(updatedJson: any): void {
        this.dataJson = updatedJson;
    }

    save(): void {
        this.dialogRef.close({data: JSON.stringify(this.dataJson), success: true});
    }
}

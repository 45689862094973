import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-image-modal',
    templateUrl: './image-modal.component.html',
})
export class ImageModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ImageModalComponent>,
    ) {}

    get imageUrl(): string {
        return this.data.imageUrl;
    }

    get imageAlt(): string {
        return this.data.imageAlt;
    }

    closeModal() {
        this.dialogRef.close();
    }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-external-id-cell',
  templateUrl: './external-id-cell.component.html',
})
export class ExternalIdCellComponent {
    @Input() cell: any;
    @Input() baseRoute: any;

    externalRedirect(url: string): void {
        window.open(url, '_blank');
    }
}

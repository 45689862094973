import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable, of, switchMap, tap} from "rxjs";
import {catchError} from "rxjs/operators";
import {Token} from "@angular/compiler";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserManagementService{

    constructor(public httpClient: HttpClient,
                private cookieService: CookieService){
    }


    get keyAccessToken(): string {
        return this.cookieService.get('accessToken') ?? '';
    }

    private _saveToken(resp: any) {
        this.cookieService.set('accessToken', resp?.data?.tokens?.accessToken);
        this.cookieService.set('refreshToken', resp?.data?.tokens?.refreshToken);
    }

    private getHeader(body?: any): HttpHeaders {

        let headers = new HttpHeaders({
            'Accept-Language': 'es-ES',
            'X-K-App': '15'
        });

        if (body) {
            headers = headers.set('Content-Type', 'application/json');
        }

        if (this.keyAccessToken) {
            headers = headers.set('Authorization', `Bearer ${this.keyAccessToken}`)
        }

        return headers;
    }

    private getResponse(method: string, url: string, body: any): Observable<any> {
        const options =
            {
                headers: this.getHeader(body),
                body: body
            };
        return this.httpClient.request(method, url, options)
            .pipe(
                tap((resp: any) => {
                    if (resp?.data?.tokens) {
                        this._saveToken(resp);
                    }
                }),
                switchMap((token: Token) => {
                    return of(token);
                }),
                catchError((err) => {
                    return of(err)
                })
            );
    }

    public createUser(body: any) {
        const url = environment.api_service + environment.v1 + '/management/Identity/UserCreation';
        return this.getResponse('POST', url, body);
    }

    public updateUser(body: any) {
        const url = environment.api_service + environment.v1 + '/management/Identity/UserUpdate';
        return this.getResponse('PUT', url, body);
    }

    public deleteUser(body: any) {
        const url = environment.api_service + environment.v1 + '/management/Identity/UserDeletion';
        return this.getResponse('DELETE', url, body);
    }

    public syncSpreeUsers(){
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const url = environment.api_service + environment.v1 + '/management/Identity/SpreeUserSync/Start';
        return this.getResponse('POST', url, {headers: headers});
    }

    public setUserTiers(body: any) {
        const url = environment.api_service + environment.v1 + '/management/Identity/Users/Tiers';
        return this.getResponse('POST',url,body);
    }

    public setUserPriceTier(body: any) {
        const url = environment.api_service + environment.v1 + '/management/Identity/Users/PriceTiers';
        return this.getResponse('POST',url,body);
    }

    public resetPassword(body: any){
        const url = environment.api_service + environment.v1 + '/management/Identity/Users/AdminPasswordReset';
        return this.getResponse('POST',url,body);
    }

    public getEnum(enumName: string) {
        const url = environment.api_service + environment.v1 + "/discovery/enum";
        const httpParams = new HttpParams().set('EnumName', enumName);
        return this.httpClient.get(url, {params: httpParams});
    }
}

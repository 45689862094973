import { Component, OnInit, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Router } from '@angular/router';
import {isArray} from 'lodash';
import {NotificationService} from '../../services/notifications.service';
import {AuthService} from '../../../core/auth/auth.service';

@Component({
    selector: "app-id-table-template",
    templateUrl: "./template-id-table.component.html",
    styleUrls: ["./template-id-table.component.scss"],
})
export class TemplateIdTableComponent implements OnInit {
    @Input() id: string = "";
    @Input() permission: string = "";
    @Input() buttonColor: string = "accent";
    @Input() isTable: boolean = true;
    @Input() navigate: string | Array<any> = ""; //[navigate]="'users/'+ id + '/refill-requests'"
    isHavePermissionPanel: boolean = false;
    isCanNavigate: boolean = false;
    isValidId = false;

    constructor(
        private clipboardService: ClipboardService,
        private notificationService: NotificationService,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.isHavePermissionPanel =
            this.permission === "" || this.permission === undefined
                ? true
                : this.authService.hasPermission(this.permission);

        this.isCanNavigate =
            this.isHavePermissionPanel && this.navigate ? true : false;
        this.isValidId = this.id ? this.id !== '00000000-0000-0000-0000-000000000000' :  false;
    }

    navigateRute(event: any) {
        console.log('EVENT: ', event);
        if (event.metaKey || event.ctrlKey) {
            if (this.isCanNavigate) {
                window.open(this.navigate.toString(), '_blank');
            }
        } else {
            if (this.isCanNavigate) {
                this.router.navigate(isArray(this.navigate) ? this.navigate : [this.navigate]);
            }
        }
    }

    getSplitId(): string {
        return this.id.split("-")[0] + "...";
    }

    callServiceToCopy() {
        this.clipboardService.copy(this.id);
        this.notificationService.success("TEXT_COPIED_TO_CLIPBOARD");
    }
}

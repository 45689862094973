import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {faEye, faEyeSlash, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute} from "@angular/router";
import {UserManagementService} from "../../../modules/user/services/user-management.service";
import {NotificationService} from "../../services/notifications.service";

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
})
export class ResetPasswordDialogComponent implements OnInit,OnDestroy{
    faSpinner = faSpinner;
    redirect: any;
    fieldPasswordType = 'password';
    iconPasswordType = faEyeSlash;
    iconConfirmPasswordType = faEyeSlash;
    showConfirmPassword: boolean = true;
    fieldConfirmPasswordType = 'password';
    showPassword: boolean = false;
    destroy$: Subject<boolean> = new Subject<boolean>();
    isSaving: boolean = false;
    isLoading: boolean = false;
    form: FormGroup;
    id: string;


    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userManagementService: UserManagementService,
        private notificationService: NotificationService,
        private route: ActivatedRoute,
    ) {
        this.id = data.id;
        this.form = this.fb.group(
            {
                password: [null, [Validators.required, Validators.minLength(6),
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=[\\]{};:"|,.<>/?]).{6,}$')]],
                confirmPassword: [null, [Validators.required, Validators.minLength(6),
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=[\\]{};:"|,.<>/?]).{6,}$')
                ]]
            },
            {validator: this.passwordMatchValidator}
        );
    }

    ngOnInit() {
        this.route.queryParams
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                if (params) {
                    this.redirect = params['redirect'];
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    disableForm() {
        this.form.disable();
    }
    enableForm() {
        this.form.enable();
    }
    disableLoading() {
        this.isLoading = false;
        this.isSaving = false;
    }
    passwordMatchValidator(group: FormGroup) {
        const password = group.get('password')?.value;
        const confirmPassword = group.get('confirmPassword')?.value;

        if (password !== confirmPassword) {
            return {passwordMatch: true};
        }
        return null;
    }

    get passwordControl(): FormControl {
        return this.form.get('password') as FormControl;
    }

    get confirmPasswordControl(): FormControl {
        return this.form.get('confirmPassword') as FormControl;
    }

    showHidePassword() {
        this.showPassword = !this.showPassword;
        if (!this.showPassword) {
            this.iconPasswordType = faEye;
            this.fieldPasswordType = 'text';
        } else {
            this.iconPasswordType = faEyeSlash;
            this.fieldPasswordType = 'password';
        }
    }

    showHideConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
        if (!this.showConfirmPassword) {
            this.iconConfirmPasswordType = faEye;
            this.fieldConfirmPasswordType = 'text';
        } else {
            this.iconConfirmPasswordType = faEyeSlash;
            this.fieldConfirmPasswordType = 'password';
        }
    }

    hasPasswordError(): boolean {
        const control = this.form.get('password');
        return control && control.hasError('pattern')
            && !control.hasError('required')
            && !control.hasError('minlength')
            && control.touched;
    }

    hasConfirmPasswordError(): boolean {
        const control = this.form.get('confirmPassword');
        return control && control.hasError('pattern')
            && !control.hasError('required')
            && !control.hasError('minlength')
            && control.touched;
    }

    submit() {
        this.isSaving = true;
        this.isLoading = true;
        this.disableForm();
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        this.userManagementService.resetPassword({
            userId: this.id,
            newPassword: this.form.value.password
        }).subscribe((response: any) => {
            if (!response.error) {
                this.notificationService.success(response.message);
                this.dialogRef.close(response);
            } else {
                this.notificationService.error(response.error.message);
                this.enableForm();
                this.disableLoading();
            }

        });
    }

}

<dx-data-grid [dataSource]="dataSource" [remoteOperations]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

    <div *dxTemplate="let data of 'titleTemplate'">
        <span class="font-bold text-2xl text-accent">{{ 'WALLET_OPERATIONS' | transloco }}</span>
    </div>
    <div *dxTemplate="let data of 'refresh'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="refresh()"
                matTooltip="{{'REFRESH' | transloco}}">
            <mat-icon>sync</mat-icon>
        </button>
    </div>
    <div *dxTemplate="let data of 'clear'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="clear()"
                matTooltip="{{'CLEAN_FILTER' | transloco}}">
            <mat-icon>filter_alt_off</mat-icon>
        </button>
    </div>
    <div *dxTemplate="let data of 'clear-all'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="clearFilterSorting()"
                matTooltip="{{'CLEAN_FILTER_SORTING' | transloco}}">
            <mat-icon>restore_page</mat-icon>
        </button>
    </div>

    <dxi-column dataField="id" [width]="110" dataType="string" cellTemplate="cellTemplateIds"
                [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'">
        <app-id-table-template [id]="cell.text"></app-id-table-template>
    </div>

<!--    <dxi-column dataField="userEmail" caption="{{'USER_EMAIL' | transloco}}" dataType="string"></dxi-column>-->
    <dxi-column *ngIf="permission != 'GetKwalletOperationsQuery-User'" dataField="userEmail" alignment="left" caption="{{'USER_EMAIL' | transloco}}"
                cellTemplate="cellTemplate" dataType="string"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplate'">
        <button (click)="navigateToUserDetails(cell)" *ngIf="isHaveUserDetailsPermission" mat-raised-button color="primary"
           class="px-2 rounded-sm shadow-none">{{cell.text}}</button>
        <button mat-raised-button color="primary" *ngIf="!isHaveUserDetailsPermission"
                class="fullNameClassWithoutPermission">{{cell.text}}</button>
    </div>

    <dxi-column dataField="internalDescription" caption="{{'DESCRIPTION' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="paymentOperationId" alignment="center" caption="{{'PAYMENT_OPERATION_ID' | transloco}}" dataType="string" cellTemplate="cellTemplateIds"
                alignment="center" [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'" class="flex justify-center">
        <app-id-table-template [id]="cell.text"></app-id-table-template>
    </div>

    <dxi-column dataField="paymentTransactionId" alignment="center" caption="{{'TRANSACTION_ID' | transloco}}" dataType="string" cellTemplate="cellTemplateIds"
                alignment="center" [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'" class="flex justify-center">
        <app-id-table-template [id]="cell.text"></app-id-table-template>
    </div>

    <dxi-column dataField="peerName" caption="{{'PEER_NAME' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="amount" alignment="center" caption="{{'AMOUNT' | transloco}}" dataType="number"></dxi-column>
    <dxi-column dataField="ledgerStatus" alignment="center" caption="{{'LEDGER_STATUS' | transloco}}" dataType="string"></dxi-column>
    <dxi-column dataField="kwalletOperationVisibility" alignment="center" caption="{{'K_WALLET_OPERATION_VISIBILITY' | transloco}}" dataType="string"></dxi-column>
    <dxi-column dataField="kwalletOperationType" alignment="center" caption="{{'TYPE' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="kwalletOperationFlowDirection" alignment="center" caption="{{'FLOW_DIRECTION' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="date" sortOrder="desc" caption="{{'DATE' | transloco}}" dataType="datetime"
                cellTemplate="dateCreatedTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'dateCreatedTemplate'">
        {{ cell.data.createdAt | date: 'd MMM yyyy H:mm:ss a' }}
    </div>

    <dxo-paging [pageSize]="pageSize"></dxo-paging>
    <dxo-pager visible="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
               [showNavigationButtons]="true" [showInfo]="true"
               infoText="{{'PAGE' | transloco}} {0} {{'OF' | transloco}} {1} ({2} {{'RESULTS' | transloco}})">
    </dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-state-storing [enabled]="true"></dxo-state-storing>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
</dx-data-grid>

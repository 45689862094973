import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LaNaveService } from 'app/modules/la-nave/services/la-nave.service';

@Component({
  selector: 'app-jsons-print',
  templateUrl: './jsons-print.component.html',
  styleUrls: ['./jsons-print.component.scss']
})
export class JsonsPrintComponent implements OnInit {
  dataJson: string;
  isJson: boolean;
  title: string;
  items: any = [];
  is_loading = false;

  constructor(
    private dialogRef: MatDialogRef<JsonsPrintComponent>,
    private service: LaNaveService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    // this.isJson = data.isJson && data.isJson == true;
    // this.dataJson = this.isJson ? data.data : JSON.parse(data.data);
    // this.dataJson = this.isJson ? data.data : data.data.substr(1, data.data.length -2);
  }

  ngOnInit(): void {
    this.is_loading = true;
    this.service.getDetails(this.data?.id).subscribe({
      next: (data: any) => {
        this.is_loading = false;
        if (data.success) {
          data.data.externalInteractions = data?.data?.externalInteractions.map(data => {
            data.request = JSON.parse(data?.request);
            data.response = JSON.parse(data?.response);
            return data;
          })
          this.items = data.data.externalInteractions;
        } else {
          console.log(data?.message);
        }
      },
      error: (e) => {
        this.is_loading = false;
        console.log(e);
      },
    })
  }
}

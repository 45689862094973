import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxDataGridModule } from 'devextreme-angular';
import { TranslocoModule } from '@ngneat/transloco';
import { BaseDataGrid } from './utils/base-data-grid';
import { MaterialModule } from '../material.module';
import { TemplateIdTableComponent } from './components/template-id-table/template-id-table.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { JsonViewerComponent } from "./components/json-viewer/json-viewer.component";
import { HasPermissionDirective } from "./directives/has-permission.directive";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardTableComponent } from "./components/tables/card-table/card-table.component";
import { WalletOperationTableComponent } from "./components/tables/wallet-operation-table/wallet-operation-table.component";
import { FailedPaymentAttemptTableComponent } from "./components/tables/failed-payment-attempt-table/failed-payment-attempt-table.component";
import { CardLimitTableComponent } from "./components/tables/card-limit-table/card-limit-table.component";
import { ApproveAndDenyVerificationDialogComponent } from "./components/approve-and-deny-verification-dialog/approve-and-deny-verification-dialog.component";
import { JsonsPrintComponent } from './components/jsons-print/jsons-print.component';
import { LoadModalComponent } from './components/load-modal/load-modal.component';
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {MenuItemComponent} from "./components/menu-item/menu-item.component";
import {ConfirmNoteDialogComponent} from "./components/confirm-note-dialog/confirm-note-dialog.component";
import {
    StartVerificationDialogComponent
} from "./components/start-verification-dialog/start-verification-dialog.component";
import {ImageModalComponent} from "./components/image-modal/image-modal.component";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {NgJsonEditorModule} from "ang-jsoneditor";
import { ShowListTextDialogComponent } from './components/show-list-text/show-list-text-dialog.component';
import { DetectTruncateDirective } from './directives/detect-truncate.directive';
import { MatTooltipModule} from "@angular/material/tooltip";
import { ResetPasswordDialogComponent } from './components/reset-password-dialog/reset-password-dialog.component';

@NgModule({
    declarations: [
        BaseDataGrid,
        TemplateIdTableComponent,
        JsonViewerComponent,
        HasPermissionDirective,
        ConfirmDialogComponent,
        ApproveAndDenyVerificationDialogComponent,
        CardTableComponent,
        WalletOperationTableComponent,
        FailedPaymentAttemptTableComponent,
        CardLimitTableComponent,
        JsonsPrintComponent,
        LoadModalComponent,
        MenuItemComponent,
        ConfirmNoteDialogComponent,
        StartVerificationDialogComponent,
        ImageModalComponent,
        ShowListTextDialogComponent,
        DetectTruncateDirective,
        ResetPasswordDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        DxDataGridModule,
        MaterialModule,
        ClipboardModule,
        NgxJsonViewerModule,
        FontAwesomeModule,
        NgxMatIntlTelInputComponent,
        NgJsonEditorModule,
        NgxIntlTelInputModule,
        NgJsonEditorModule,
        MatTooltipModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        DxDataGridModule,
        BaseDataGrid,
        MaterialModule,
        TemplateIdTableComponent,
        ClipboardModule,
        NgxJsonViewerModule,
        JsonViewerComponent,
        HasPermissionDirective,
        ConfirmDialogComponent,
        ApproveAndDenyVerificationDialogComponent,
        FontAwesomeModule,
        CardTableComponent,
        WalletOperationTableComponent,
        FailedPaymentAttemptTableComponent,
        CardLimitTableComponent,
        LoadModalComponent,
        NgxMatIntlTelInputComponent,
        MenuItemComponent,
        ConfirmNoteDialogComponent,
        StartVerificationDialogComponent,
        ImageModalComponent,
        NgxIntlTelInputModule,
        NgJsonEditorModule,
        ShowListTextDialogComponent,
        DetectTruncateDirective,
    ]
})
export class SharedModule {
}

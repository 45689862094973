<mat-toolbar color="primary" mat-dialog-title fxLayout fxLayoutAlign="center center">
    <span>{{ title | transloco | uppercase }}</span>
</mat-toolbar>

<mat-dialog-content class="p-6" *ngIf="!isError">
    <ul class="list-disc pl-5" *ngIf="data.data.length > 0">
        <li *ngFor="let item of data.data" class="mb-2">
            <span class="font-medium">{{ item.label }}</span> (ID: {{ item.id }})
        </li>
    </ul>
    <div *ngIf="data.data.length == 0" class="text-center">
        <span class="font-extrabold">{{ emptyListMessage | transloco }}</span>
    </div>
</mat-dialog-content>

<mat-dialog-content class="p-6" *ngIf="isError">
    <ul class="list-disc pl-5" *ngIf="data.data.length > 0">
        <li *ngFor="let item of data.data" class="mb-2">
            <span class="font-medium" style="color:red;">{{ item }}</span>
        </li>
    </ul>
</mat-dialog-content>

<mat-dialog-actions class="justify-center">
    <button mat-button mat-dialog-close class="text-white px-4 py-2 rounded" style="background-color: #0f172a">
        {{ 'CLOSE' | transloco }}
    </button>
</mat-dialog-actions>

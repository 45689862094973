import {AfterViewInit, Component, OnInit, Input, OnDestroy} from '@angular/core';
import { BaseDataGrid } from '../../../../shared/utils/base-data-grid';
import DataSource from 'devextreme/data/data_source';
import { TranslocoService } from '@ngneat/transloco';
import {Subscription} from "rxjs";
import {UserService} from "../../../../modules/user/services/user.service";
import {CardLimitService} from "../../../../modules/card-limit/services/card-limit-service";
import {UserCardLimitService} from "../../../../modules/user/services/user-card-limit.service";
import {CardLimitChangeDialogComponent} from "../../../../modules/card-limit/components/card-limit-change-dialog/card-limit-change-dialog.component";
import {filter} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-payment-card-limit-table',
    templateUrl: './card-limit-table.component.html'
})
export class CardLimitTableComponent extends BaseDataGrid implements OnInit, AfterViewInit, OnDestroy {
    @Input() permission = 'GetUserCardLimitQuery';
    pageSize: number = 10;
    infoSubscription: Subscription;
    user: any;

    constructor(
        private cardLimitService: CardLimitService,
        private userCardLimitService: UserCardLimitService,
        private _translocoService: TranslocoService,
        private userService: UserService,
        public dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        switch (this.permission) {
            case 'GetUserCardLimitQuery': {
                this.dataSource = new DataSource({
                    key: "id",
                    load: (loadOptions: any) =>
                        // this.loadFetchPaymentCardsPanel(loadOptions)
                        this.cardLimitService.getAll(loadOptions, this.isRefresh)
                });
                break;
            }
            //  'GetUserCardLimitQuery-User'
            default: {
                this.infoSubscription = this.userService.userInfoSubject.subscribe(user => {
                    this.user = user;
                });
                this.dataSource = new DataSource({
                    key: "id",
                    load: (loadOptions: any) =>
                        this.userCardLimitService.getAll(loadOptions, this.isRefresh, [], {'userId==': this.user.id})
                });
                break;
            }
        }
    }

    ngAfterViewInit(): void {
        switch (this.permission) {
            case 'GetUserCardLimitQuery': {
                this.cardLimitService.setDataGrid(this.dataGrid);
                break;
            }
            //  'GetUserCardLimitQuery-User'
            default: {
                this.userCardLimitService.setDataGrid(this.dataGrid);
                break;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.permission === 'GetUserCardLimitQuery-User') {
            this.infoSubscription.unsubscribe();
        }
    }

    editLimitCard(data: any) {
        this.dialog.open(CardLimitChangeDialogComponent, {
            width: '450px',
            data: {
                onlyOne: true,
                limit: data.limit,
                userId: data.userId,
                paymentClientId: data.paymentClientId
            },
        }).afterClosed()
            .pipe(filter((name) => name))
            .subscribe((response) => {
                if (response != 'close') {
                    this.refresh();
                }
            });
    }

    onToolbarPreparing(e): void {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'titleTemplate',
                locateInMenu: 'never',
            },
            {
                location: 'after',
                template: 'refresh',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear-all',
                locateInMenu: 'auto',
            },
        );
    }
}
